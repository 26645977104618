@font-face {
  font-family: 'JetBrains Mono';
  src: url('../../public/fonts/JetBrainsMono-VariableFont_wght.ttf')
    format('truetype-variations');
  font-weight: 1 999;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './global.scss';
