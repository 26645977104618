@layer components {
  .blinking-cursor:after {
    content: '|';
    font-family: 'JetBrains Mono';
    color: theme('colors.font-color');
    animation: blink 0.6s step-start infinite;
  }
  .modal {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }

  .modal.show {
    opacity: 1;
    pointer-events: visible;
  }

  .modal-content {
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
  }

  .modal-show .modal-content {
    transform: translateY(0);
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
}
html,
body {
  background-color: theme('colors.pure-black');
}

section {
  @apply flex;
  @apply justify-center;
  @apply flex-col;
  @apply relative;
  @apply w-screen;
  @apply h-screen;
}
